import React from "react";
import "../components/data/alphabet-amazon/styles.css";
import RadarChart from "../components/data/alphabet-amazon/RadarChart";
import SEO from "../components/seo"

import Grid from "@material-ui/core/Grid";

import HomeIcon from '@material-ui/icons/Home';

import { Link } from "gatsby"

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ApartmentIcon from '@material-ui/icons/Apartment';
import TimelineIcon from '@material-ui/icons/Timeline';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';


// {
//   /* <Grid item xs={12} sm={6}></Grid> */
// }

// I don't think styles does anything, I've turned it off

export default function AlphabetAmazon() {
  return (
    <div
      className="App"
      style={{ maxWidth: "700px", border: "auto", margin: "auto", marginTop: "20px", marginBottom: "20px"}}
    >
    <SEO
      title="Alphabet vs Amazon"
      description="A look at some of the important numbers behind these tech giants to see how they stack up. A visualisation comparing the companies in FAANG"
    />


{/* Header */}
    <Grid
        container
        direction="row"
        spacing={1}
        justify="flex-start"
        alignItems="flex-start"
        style={{paddingTop:"0px"}}
      >

      <Grid item xs={2}>
      <Link to="/" style={{ textDecoration: "none" }}>            
      <HomeIcon 
            style={{ height: "30px", width: "30px", paddingTop: "0px", color: "steelBlue", marginLeft: "20px"}}/>
      </Link>
      </Grid>

      <Grid item xs={8}>
        <h1 style={{ textAlign: "center", fontSize:"1.3rem", paddingTop:"5px"}}>Alphabet Vs Amazon</h1>
      </Grid>

      </Grid>




{/* Content */}
      <Grid
        container
        direction="row"
        spacing={1}
        justify="center"
        alignItems="center"
        style={{paddingTop:"0px"}}
      >






{/* SECTION 1 */}

{/* left section */}
        <Grid item xs={5} style={{textAlign: "right"}}>
          <p style={{fontSize: "0.8rem", marginTop:"auto", marginBottom:"auto"}}>
          <strong>$161.8 billion</strong> - up from $136.8 billion in the previous year
          </p>
        </Grid>

{/* icon */}
        <Grid item xs={2} >
        <MonetizationOnIcon
            style={{ height: "100%", width: "100%", paddingTop: "0px", color: "steelBlue", marginLeft: "0px"}}/>
        </Grid>

{/* right section */}
        <Grid item xs={5} style={{textAlign: "left"}}>
        <p style={{fontSize: "0.8rem", marginTop:"auto", marginBottom:"auto"}}>
        <strong>$280.5 billion</strong> - up from $232.8 billion in the previous year
          </p>
        </Grid>

{/* section title & divider */}
        <Grid item xs={3} style={{textAlign: "right"}}>
            <hr />
        </Grid>

        <Grid item xs={3} style={{textAlign: "center"}}>
            <h2 style={{fontSize: "1rem", color:"grey"}}>Revenue</h2>
        </Grid>

        <Grid item xs={3} style={{textAlign: "left"}}>
            <hr />
        </Grid>


{/* SECTION 2 */}

{/* left section */}
<Grid item xs={5} style={{textAlign: "right"}}>
          <p style={{fontSize: "0.8rem", marginTop:"auto", marginBottom:"auto"}}>
          Google network ads $134.8 billion - <strong>83.3%</strong> of total revenue
          </p>
        </Grid>

{/* icon */}
        <Grid item xs={2} >
        <AccountBalanceWalletIcon
            style={{ height: "100%", width: "100%", paddingTop: "0px", color: "steelBlue", marginLeft: "0px"}}/>
        </Grid>

{/* right section */}
        <Grid item xs={5} style={{textAlign: "left"}}>
        <p style={{fontSize: "0.8rem", marginTop:"auto", marginBottom:"auto"}}>
        Online stores $141.2 billion - <strong>50.3%</strong> of total revenue
          </p>
        </Grid>

{/* section title & divider */}
        <Grid item xs={3} style={{textAlign: "right"}}>
            <hr />
        </Grid>

        <Grid item xs={3} style={{textAlign: "center"}}>
            <h2 style={{fontSize: "1rem", color:"grey"}}>Main Income</h2>
        </Grid>

        <Grid item xs={3} style={{textAlign: "left"}}>
            <hr />
        </Grid>


{/* SECTION 3 */}

{/* left section */}
<Grid item xs={5} style={{textAlign: "right"}}>
          <p style={{fontSize: "0.8rem", marginTop:"auto", marginBottom:"auto"}}>
          Google - YouTube - Cloud Services - Google Fiber - Waymo - DeepMind etc
          </p>
        </Grid>

{/* icon */}
        <Grid item xs={2} >
        <ApartmentIcon
            style={{ height: "100%", width: "100%", paddingTop: "0px", color: "steelBlue", marginLeft: "0px"}}/>
        </Grid>

{/* right section */}
        <Grid item xs={5} style={{textAlign: "left"}}>
        <p style={{fontSize: "0.8rem", marginTop:"auto", marginBottom:"auto"}}>
         Online retail - Physical products - AWS - Prime subscription - IMDb - Wholefoods - Twitch etc
          </p>
        </Grid>

{/* section title & divider */}
        <Grid item xs={3} style={{textAlign: "right"}}>
            <hr />
        </Grid>

        <Grid item xs={3} style={{textAlign: "center"}}>
            <h2 style={{fontSize: "1rem", color:"grey"}}>Income Streams</h2>
        </Grid>

        <Grid item xs={3} style={{textAlign: "left"}}>
            <hr />
        </Grid>


{/* SECTION 4 */}

{/* left section */}
<Grid item xs={5} style={{textAlign: "right"}}>
          <p style={{fontSize: "1.4rem", marginTop:"auto", marginBottom:"auto"}}>
          $1.03 trillion
          </p>
        </Grid>

{/* icon */}
        <Grid item xs={2} >
        <TimelineIcon
            style={{ height: "100%", width: "100%", paddingTop: "0px", color: "steelBlue", marginLeft: "0px"}}/>
        </Grid>

{/* right section */}
        <Grid item xs={5} style={{textAlign: "left"}}>
        <p style={{fontSize: "1.4rem", marginTop:"auto", marginBottom:"auto"}}>
        $1.55 trillion
          </p>
        </Grid>

{/* section title & divider */}
        <Grid item xs={3} style={{textAlign: "right"}}>
            <hr />
        </Grid>

        <Grid item xs={3} style={{textAlign: "center"}}>
            <h2 style={{fontSize: "1rem", color:"grey"}}>Market cap</h2>
        </Grid>

        <Grid item xs={3} style={{textAlign: "left"}}>
            <hr />
        </Grid>



{/* SECTION 5 */}

{/* left section */}
<Grid item xs={5} style={{textAlign: "right"}}>
          <p style={{fontSize: "1.4rem", marginTop:"auto", marginBottom:"auto"}}>
          123,048
          </p>
        </Grid>

{/* icon */}
        <Grid item xs={2} >
        <SupervisedUserCircleIcon
            style={{ height: "100%", width: "100%", paddingTop: "0px", color: "steelBlue", marginLeft: "0px"}}/>
        </Grid>

{/* right section */}
        <Grid item xs={5} style={{textAlign: "left"}}>
        <p style={{fontSize: "1.4rem", marginTop:"auto", marginBottom:"auto"}}>
          840,000
          </p>
        </Grid>

{/* section title & divider */}
        <Grid item xs={3} style={{textAlign: "right"}}>
            <hr />
        </Grid>

        <Grid item xs={3} style={{textAlign: "center"}}>
            <h2 style={{fontSize: "1rem", color:"grey"}}>No. Employees</h2>
        </Grid>

        <Grid item xs={3} style={{textAlign: "left"}}>
            <hr />
        </Grid>

















      </Grid>






      {/* Footer, split later */}
      <Grid
        container
        direction="column"
        spacing={0}
        justify="center"
        alignItems="center"
        style={{marginLeft:"auto", marginRight:"auto"}}
      >

        <Grid item xs={11} style={{marginLeft:"auto", marginRight:"auto"}}>
        
          

          {/* Placeholder chart */}
          <RadarChart/>
          <p style={{fontSize:"0.8rem", color:"grey", paddingTop:"10px"}}>The extents of the chart represent the higher value with the lower value being a percentage of the higher value. Amounts in the first section are in USD. Values are from 2019 financial year unless stated otherwise. Date - 16th July 2020</p>

          <h2>Data Visualisation</h2>
          <p>Click a title in the legend to toggle the data shown in the chart.</p>
          <p>I have included data for each company in FAANG to compare how Alphabet and Amazon compare visually to other tech giants. For the radar chart, I had to find a way to normalise each feature so one didn't overpower all of the others. As you can imagine, the number for revenue is going to be on a completely different scale and look different to one for total number of employees.</p>
          <p>As a result of my scaling and my choice of chart, some companies are favoured heavily over others. Amazon takes up most of the chart, only beaten on one metric, where Netflix is just a blip in the middle. Does this reflect real life? I will explain why I chose each metric below and what I think it does to add value to the chart.</p>

          <h2>Revenue</h2>
          <p>The revenue is a good measure of how well a company is doing and is an easy way to make a quick comparison between different companies. Revenue is the total income from the sale of good and services.</p>
          <p>I chose this and not profit because profit is what is left in the business after all expenses. Including employee salaries. Amazon and Alphabet are both smart when it comes to the amount they have in the business at the end of each quarter and profit at this size of business won't tell us much.</p>
          <p>Revenue is shown on the chart as a percentage of the company in FAANG with the highest revenue. E.g. Amazon has the highest revenue, making reach the extents of the chart. Facebook has 25% of the revenue of Amazon, making it sit at 25 on the axis.</p>

          <h2>Income Streams</h2>
          <p>Streams is an indicator for diversity of products and services. Originally, I wanted to give a point for each industry the company occupied. Eventually I settled on the number of high income active subsidiaries of the company.</p>
          <p>It was difficult to get a number for each because of the company structures and difficulty of finding the correct information. For example, these tech giants have hundreds of acquisitions going back years and years.</p>
          <p>Which ones have been merged into parent company? which ones still operate under their original name? Also, Alphabet has a number of subsidiaries, one of which is Google. Then Google has its own list of subsidiaries.</p>
          <p>Again, this axis show the company with the highest number of individual income streams reaching the full extents with every other company being represented by a percentage of this.</p>

          <h2>Market cap</h2>
          <p>Market capitalisation is the price of a single share in the company multiplied by the total number of outstanding shares. I'm not an expert in the stock market but like revenue, market cap is a way of getting a quick indicator of a company's importance.</p>
          <p>It's amazing to think that two of the 5 companies in FAANG have well over a $1 trillion market cap.</p>
          <p>To calculate this one, I took the highest market cap (Apple) and the other companies are a percentage of this on the chart.</p>

          <h2>Number of Employees</h2>
          <p>A straight forward one here. The company with the highest number of employees is at 100 and the others are a percentage of this.</p>
          <p>I chose to do it this way because employment helps the economy by giving the public an opportunity to earn money then buy goods & services.</p>
          <p>I considered engineering the feature to give a higher rating to companies that earn more per employee but I decided against it. These companies hold a lot of responsibility and power.</p>
          <p>While it might generate more profit to employ less people and work them harder, these employees would carry a heavier burden and would likely be more stressed. Which, if it doesn't lower productivity, it will increase churn rate.</p>

          <h2>Main Income</h2>
          <p>As mentioned above, this feature favoured some companies much more than others. Like income streams, it is a measure of diversity and a company is rewarded for having a lower percentage total revenue for their top source of income. Amazon being the most diverse by far.</p>
          <p>Where income streams measures the total, main income gives an idea of how much dependence the company has on their main source of income.</p>
          <p>I took the highest earning stream of income for the company and made it a percentage of their total income. Then I reversed this percentage by subtracting 100. After this I made the highest 100 on the chart and the rest became a percentage of this.</p>
          

          <h2>Notes</h2>
          <p>This page was made using React/ Gatsby with Chart js for the Radar Chart. Get in touch through the about page if you want learn more or ask any questions.</p>

          <h2>Sharing</h2>
          <p>Please share this page if you enjoyed it, just copy the URL in the address bar. Or link to it from your own site using - "faang data visualisation", "Alphabet vs Amazon", "Campbell Data Science" or anything similar you can think of, it really helps.</p>


    
          <h2 style={{color:"grey", fontSize:"1rem", marginBottom:"15px"}}>Sources</h2>

          <p style={{color:"grey", fontSize:"0.9rem", marginBottom:"5px"}}>statisa.com - <a
            href="https://www.statista.com/"
            target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none" }}
            >
            General company statistics
           </a></p>

          <p style={{color:"grey", fontSize:"0.9rem", marginBottom:"5px"}}>statistic.com - <a
            href="https://statstic.com/"
            target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none" }}
            >
            General company statistics
           </a></p>

          <p style={{color:"grey", fontSize:"0.9rem", marginBottom:"5px"}}>visualcapitalist.com - <a
            href="https://www.visualcapitalist.com/breaking-amazon-makes-money/"
            target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none" }}
            >
            How Amazon Makes money
           </a></p>

          <p style={{color:"grey", fontSize:"0.9rem", marginBottom:"5px"}}>investopedia.com - <a
            href="https://www.investopedia.com/insights/how-netflix-makes-money/"
            target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none" }}
            >
            How Netflix Makes money
           </a></p>

           <p style={{color:"grey", fontSize:"0.9rem", marginBottom:"5px"}}>investopedia.com - <a
            href="https://www.investopedia.com/apple-s-5-most-profitable-lines-of-business-4684130#:~:text=For%20the%20fiscal%20year%202019,company's%20sales%3B%20the%20iPad%20accounted"
            target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none" }}
            >
            Apple's Lines of Business
           </a></p>

           <p style={{color:"grey", fontSize:"0.9rem", marginBottom:"5px"}}>investor.fb.com - <a
            href="https://investor.fb.com/investor-news/press-release-details/2020/Facebook-Reports-Fourth-Quarter-and-Full-Year-2019-Results/default.aspx"
            target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none" }}
            >
            Facebook's Financial Highlights
           </a></p>

           <p style={{color:"grey", fontSize:"0.9rem", marginBottom:"5px"}}>en.wikipedia.org - <a
            href="https://en.wikipedia.org/wiki/List_of_mergers_and_acquisitions_by_Apple"
            target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none" }}
            >
            Apple mergers and acquisitions
           </a></p>

           <p style={{color:"grey", fontSize:"0.9rem", marginBottom:"5px"}}>investopedia.com - <a
            href="https://www.investopedia.com/articles/personal-finance/051815/top-11-companies-owned-facebook.asp"
            target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none" }}
            >
            Companies owned by Facebook
           </a></p>

           <p style={{color:"grey", fontSize:"0.9rem", marginBottom:"5px"}}>kamilfranek.com - <a
            href="https://www.kamilfranek.com/what-companies-alphabet-google-owns/"
            target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none" }}
            >
            Alphabet company structure
           </a></p>

           <p style={{color:"grey", fontSize:"0.9rem", marginBottom:"5px"}}>Wikipedia.org - 
            <a
            href="https://en.wikipedia.org/wiki/Alphabet_Inc."
            target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none" }}
            >
              List of Alphabet subsidiaries
           </a></p>

           <p style={{color:"grey", fontSize:"0.9rem", marginBottom:"5px"}}>en.wikipedia.org - <a
            href="https://en.wikipedia.org/wiki/Amazon_(company)#:~:text=Subsidiaries,-See%20also%3A%20List&text=Amazon%20owns%20over%2040%20subsidiaries,%2C%20Teachstreet%2C%20Twitch%20and%20Zappos."
            target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none" }}
            >
            Amazon subsidiaries
           </a></p>

           <p style={{color:"grey", fontSize:"0.9rem", marginBottom:"20px"}}>investopedia.com - <a
            href="https://www.investopedia.com/articles/investing/091015/overview-businesses-owned-amazon.asp"
            target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none" }}
            >
            Businesses owned by Amazon
           </a></p>


          <Link to="/" style={{ textDecoration: "none" }}>
                Go back to the homepage
          </Link>
            
        </Grid>

        </Grid>



    </div>
  );
}
