import React from "react";

// import Chart from "chart.js";
import { Radar } from "react-chartjs-2";


// Netflix, Apple and Facebook have no fill

function RadarChart() {

    // function handleClick(event){
    //     event.preventDefault();
    //     console.log("clicked");
    // }

  return (
    <div style={{ height: "350px", marginBottom: "0px" }}>

      <Radar

        data={{

        labels: ["Revenue", "Streams", "Cap", "No. Employees", "Main Income"],
        
        datasets:[
          {
          label: "Alphabet",
          fill: true,
          backgroundColor: "rgba(0,153,0,0.2)",
          borderColor: "rgba(0,153,0,1)",
          //pointBorderColor: "#fff",
          pointBackgroundColor: "rgba(0,153,0,1)",
          data: [57,97,60,15,14]
        }, {
          label: "Amazon",
          fill: true,
          backgroundColor: "rgba(255,128,0,0.2)",
          borderColor: "rgba(255,128,0,1)",
          //pointBorderColor: "#fff",
          pointBackgroundColor: "rgba(255,128,1)",
          data: [100,100,93,100,100]
        }
        , {
          label: "Facebook",
          fill: false,
          backgroundColor: "rgba(51,51,255,0.2)",
          borderColor: "rgba(51,51,255,1)",
          //pointBorderColor: "#fff",
          pointBackgroundColor: "rgba(51,51,255,1)",
          data: [25,12,41,5,0],
          hidden: true
        }, {
          label: "Apple",
          fill: false,
          backgroundColor: "rgba(128,128,128,0.2)",
          borderColor: "rgba(128,128,128,1)",
          //pointBorderColor: "#fff",
          pointBackgroundColor: "rgba(128,128,128,1)",
          data: [93,30,100,16,4],
          hidden: true
        }, {
          label: "Netflix",
          fill: false,
          backgroundColor: "rgba(204,0,0,0.2)",
          borderColor: "rgba(204,0,0,1)",
          //pointBorderColor: "#fff",
          pointBackgroundColor: "rgba(204,0,0,1)",
          data: [20,3,14,1,0],
          hidden: true
        }
        ]

        }}


        options={{

          title: {
            display: true,
            text: 'A Visual Comparison of FAANG',
            fontSize: 12
          },
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: "top"
          },
          scale: {
            angleLines: {
            display: true
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100
              }
            }

        }}
      />

      

      {/* <div style={{textAlign: "center"}}>
      <Button onClick={handleClick} id="the button"
      variant="contained"
            color="primary"
            href="#contained-buttons"
            disableElevation
            style={{
              width: "25ch",
              backgroundColor: "#D8564A", 
              marginBottom: "20px",
            }}>
        Click me
      </Button>
      </div> */}

    </div>
  );
}

export default RadarChart;
